@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,600;1,100&display=swap');

$boxshadow: rgba(66, 68, 90, 1);
$background-first: rgba(238,174,202,1);
$background-second: rgba(148,187,233,1);
$background-app: #fff;

body {
  margin: 0;
  padding: 0;
  background: linear-gradient(0deg, $background-first 0%, $background-second 100%);
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Montserrat', sans-serif;
}

.app {
  width: 24rem;
  height: 34rem;
  background-color: $background-app;
  border-radius: 0.6rem;
  -webkit-box-shadow: 8px 8px 24px 0px $boxshadow;
  -moz-box-shadow: 8px 8px 24px 0px $boxshadow;
  box-shadow: 8px 8px 24px 0px $boxshadow;
  padding: 0.2rem 3rem;
  position: relative;

  h1 {
    color: #777;
    font-size: 2.5rem;
    margin-bottom: 1rem;
  }
}

.form {
  width: 100%;
  display: flex;
  gap: 0.2rem;
  
  .form__text {
    width: 18rem;
    padding: 0.9rem;
    font-size: 1.25rem;
    outline: none;
    border: 0.1rem solid #bbb;
  }

  .form__button {
    background: none;
    font-size: 2.25rem;
    padding: 0 0.6rem;
    background-color: $background-first;
    border: 0.1rem solid #eee;
    color: white;
    line-height: 2rem;
  }
  .form__button:hover{
    cursor: pointer;
    background-color: rgb(232, 155, 188);
  }
}

.todos {
  list-style: none;
  padding: 0;
  margin-top: 1.5rem;
  height: 21rem;
  overflow-x:auto;
  overflow-y:auto;

  .todos__item {
    background-color: #eee;
    position: relative;
    display: flex;
    margin-top: 1rem;

    .todos__content {
      padding-left: 1.2rem;
      font-size: 1.25rem;
      margin: 0.9rem 0;
      color: #444;
      font-weight: 600;
      overflow-x:auto;
      overflow-y:auto;
      width: 16.3rem;
    }

    .todos__link {
      position: absolute;
      right: 0%;
      color: black;
      width: 3rem;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.75rem;
      background: linear-gradient(#eee, #eee 50%, $background-first 50%, $background-first);
      background-size: 100% 200%;
      transition: background 1s;
    }

    .todos__link:hover {
      background-position: 100% 100%;
    }

    .todos__button {
      position: absolute;
      right: 12.5%;
      width: 3rem;
      height: 100%;
      font-size: 1.75rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      background-color: rgba(255, 0, 0, 0.734);
    }

    .todos__button:hover {
      cursor: pointer;
      color: #fff;
    }
  }
}

.edit {
  display: flex;
  flex-direction: column;
  .edit__content {
    font-size: 1.5rem;
    margin-top: 1.75rem;
    font-weight: 600;
    color: #555;
    overflow-x:auto;
    overflow-y:auto;
  }

  .edit__form {
    display: flex;
    gap: 0.1rem;
  }

  .edit__text {
    width: 18rem;
    padding: 0.9rem;
    font-size: 1.25rem;
    outline: none;
    border: 0.1rem solid #bbb;
  }

  .edit__button {
    background: none;
    font-size: 2.25rem;
    padding: 0 0.7rem;
    background-color: $background-first;
    border: 0.1rem solid #eee;
    color: white;
    line-height: 2rem;
    height: 4rem;
    cursor: pointer;
  }

  .edit__button:hover {
    background-color: rgb(232, 155, 188);
  }
}

.link__back {
  text-decoration: none;
  color: #333;
  font-size: 1.5rem;
  position: absolute;
  bottom: 10%;
}

::-webkit-scrollbar {
  width: 12px;
  background-color: #ccc;
  height: 9px;
}
::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: #555;
} 

@media (max-width: 520px) {
  .app {
    width: 20rem;
    padding: 0.2rem 1rem;

    .form__text {
      width: 13.5rem;
    }

    }

    .todos {
      .todos__item {
        .todos__content{
          width:12.5rem;
        }
        .todos__button {
          right: 14%;
        }
      }
    }

    .edit{
      .edit__text {
        width: 13.5rem;
      }
    }
}